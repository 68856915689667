.nav_container {
  display: flex;
  flex-direction: column;
  background: #1a1a1a;
  height: 70px;
  padding: 10px;
  align-items: center;
}

.nav_container a {
  text-decoration: none;
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-start;
}

.hamburger {
  /* width: 20%; */
  margin-right: 35%;
}

.logo {
  height: 40px;
}

.navigation_menu_close {
  display: none;
}

.expanded ul {
  margin-left: 0;
  left: 0;
  top: -19px;
  position: absolute;
  background-color: #1a1a1a;
  height: 100%;
  width: 28vh;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  font-size: 1.2rem;
  z-index: 1;
  overflow-y: auto;
  position: fixed;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10%;
}

.userImage {
  height: 40px;
  border-radius: 50px;
  border: white solid;
}

.temp_user_logo {
  height: 40px;
  width: 40px;
  display: flex;
  color: #d9d9d9;
  border: 2px solid white;
  border-radius: 50px;
}

.auth_btn {
  align-items: center;
  /* width: 60%; */
  /* border-radius: 15px; */
  /* padding: 10px; */
  border: none;
  margin-right: auto;
  margin-left: -1%;
  background: none;
  color: white;
  font-size: 1.2rem;
  font-family: 'Titillium-Regular';
  text-align: left;
}

.auth_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.auth_items {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  gap: 20px;
}

@media only screen and (min-width: 767px) and (max-width: 990px) {
  .expanded ul {
    width: 50vh;
  }
  /* .auth_btn {
    width: 70%;
  } */
  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .tablet_menu {
    display: flex;
    /* width: 10%; */
    gap: 20px;
    justify-content: space-between;
  }
  .logo {
  }
  .userImage {
  }
}

@media only screen and (min-width: 991px) {
  .expanded {
    display: none;
  }
  .userImage {
    border: none;
    /* margin-right: -60px; */
  }

  .navigation {
    display: flex; /* Updated: Display the navigation on desktop screens */
  }

  .desktop_navigation {
    color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .navigation_menu_close {
    display: none; /* Updated: Hide the expanded menu on desktop screens */
  }

  .list {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1320px;
    align-items: center;
    justify-content: space-around;
  }
  .list_menu {
    margin: 0%;
  }
  .create_movement_btn {
    background-color: #f1ec08;
    border: none;
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
    /* margin-right: -50px; */
    font-size: 14px;
    font-weight: bold;
    font-family: 'Titillium-SemiBold';
    cursor: pointer;
  }

  .create_movement_btn:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
  }

  .auth_btn_desktop {
    /* border-radius: 15px; */
    /* padding: 10px; */
    /* margin-right: auto; */
    background: none;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    /* margin-left: -10px; */
  }
}
