@import './fonts.module.css';

.loading_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
}

.loading {
  height: 180px;
  /* display: flex; */
  margin-top: 20%;
  align-items: center;
}
.loading_text {
  font-size: 18px;
  font-family: 'Titillium-SemiBold';
}

.container {
  font-family: 'Titillium-Regular';
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  min-height: 100vh;
  background-color: #fff;
}

.section1 {
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
}
.back_btn_container {
  width: 90%;
  margin: auto;
}
.back_btn {
  background-color: #f1ec08;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.s1TextBody {
  width: 90%;
  min-height: 30vh;
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
}
.step_title_main {
  font-family: 'Titillium-Regular';
  font-size: 30px;
  letter-spacing: 0.2rem;
}

.section2 {
  /* padding-left: 10%; */
  /* padding-right: 15%; */
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.auth_btn {
  background-color: #2d2a32;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  display: flex;
  align-self: flex-start;
  color: white;
}

.auth_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.title1 {
  text-align: left;
  color: #2d2a32;
  font-size: 55px;
  /* margin: auto; */
  width: 80%;
}

.subtitle1 {
  text-align: left;
  color: #2d2a32;
  font-size: 18px;
  /* font-family: 'Titillium-ExtraLight'; */
  font-family: 'Titillium-SemiBold';

  /* margin-bottom: 50px; */
  /* margin-top: 50px; */
}

.inputDivider {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  /* border: 1px solid #1e1e1e; */
  border-radius: 15px;
}

.input {
  background-color: #fff;
  color: #1e1e1e;
  font-weight: bold;
  padding: 16px 16px 16px 16px;
  border-radius: 15px;
  font-family: 'Titillium-Regular';
  font-size: 18px;
}

.formItemDivider {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

/* Input style */
.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;

  border-radius: 15px;
  /* width: 45%; */
  width: 100%;
}

.inputContainer:focus-within label {
  color: #1e1e1e;
}

.filled {
  margin-left: 4px;
  margin-bottom: 5px;
  /* font-family: 'Titillium-SemiBold'; */
  font-family: 'Titillium-Light';
}

.inputContainer label {
  position: absolute;
  pointer-events: none;
  color: #1e1e1e;
  font-size: 14px;
  line-height: 1;
  left: 22px;
}

.inputContainer input {
  border-radius: 15px;
  /* border: none; */
  padding: 16px;
  border: 1px solid #1e1e1e;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  font-family: 'Titillium-Regular';
  /* transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; */
}

.inputContainer input:focus {
  box-shadow: 0 0 0 2px #f1ec08;
}

.bottom_btn {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  margin-bottom: 5%;
}

.line {
  border-top: 1px solid #2d2a32;
  width: 100%;
  justify-content: center;
  /* margin-left: -25%; */
  /* margin-top: 15%; */
}

.continue_btn {
  font-family: 'Titillium-Light';
  padding: 0.8rem;
  border-radius: 1rem;
  border: none;
  background-color: #f1ec08;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;
  cursor: pointer;
}

.continue_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.textbox {
  display: flex;
}

.textareainput {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #1e1e1e;
  color: #1e1e1e;
  padding: 10px;
  font-family: 'Titillium-Regular';
  font-size: 1.3rem;
}

.textareainput:focus {
  box-shadow: 0 0 0 2px #eeefa8;
}
.form_btns {
  display: flex;
  justify-content: space-between;
}

.step {
  font-family: 'Oswald-Bold';
}

.step_title_sub {
  font-family: 'Titillium-Regular';
  font-size: 01.1rem;
}
.steps_title {
  font-size: 24px;
}

@media only screen and (min-width: 767px) and (max-width: 990px) {
  .back_btn_container {
    width: 15%;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .section1 {
    flex-direction: row;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .s1TextBody {
    width: 35%;
    height: 100%;
    margin: 0;
  }

  .section2 {
    width: 70%;
  }
  .container {
    min-height: 80vh;
  }
  .continue_btn {
    width: 180px;
  }
  .inputDivider {
    flex-direction: row;
  }
}

@media only screen and (min-width: 991px) {
  .loading {
    justify-content: center;
    width: 100%;
    margin-top: 30vh;
  }
  .user_icon_container {
    display: flex;
    order: 1;
    padding-right: 30px;
    padding-top: 50px;
  }
  .container {
    flex-direction: row;
  }
  .section1 {
    width: 32%;
  }
  .section2 {
    width: 68%;
  }
  .form_container {
    width: 80%;
    max-width: 950px;
    margin: auto;
    display: flex;
    gap: 15px;
    flex-direction: column;
  }
  .back_btn_container {
    margin: 50px auto 0 auto;
  }
  .s1TextBody {
    margin: 50px auto 0 auto;
    height: auto;
  }
  .step_title_main {
    width: 90%;
    font-size: 45px;
    line-height: 60px;
  }
  .step_title_sub {
    width: 90%;
    font-size: 20px;
    line-height: 30px;
  }
  .userImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    display: flex;
  }
  .input {
    padding: 14px;
  }
  .form_btns {
    width: 40%;
    max-width: 200px;
  }
  .textareainput {
    width: 100%;
  }
  .inputDivider {
    flex-direction: row;
  }
}
