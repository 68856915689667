.wrapper {
  background-color: white;
}
.body {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
  max-width: 1024px;
  font-family: 'Titillium-Regular';
  min-height: 80vh;
  /* background-color: white; */
}

.badge {
  background-color: black;
  min-height: 100px;
  height: 25%;
  display: flex;
  flex-direction: column;
}

.badgeBody {
  margin: auto;
  width: 80%;
  max-width: 1024px;
}

.badgeText {
  color: white;
  font-size: 36px;
  margin-top: 20px;
}

.line {
  background-color: #f1ec08;
  height: 1px;
  padding: 1px;
}

.s3_body {
  display: flex;
  flex-direction: column;
}

.image3 {
  height: 180px;
}

.logo {
  height: 80px;
  width: 80px;
  margin: 20px auto;
  display: flex;
}

.title {
  text-align: center;
  font-size: 40px;
  font-family: 'Titillium-Bold';
}
.subtitle {
  text-align: center;
  font-size: 20px;
  font-family: 'Titillium-Regular';
}

.charitySvg {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.btn {
  background-color: #f1ec08;
  border: none;
  padding: 10px 40px 10px 40px;
  border-radius: 10px;
  /* margin-right: -50px; */
  font-size: 14px;
  font-weight: bold;
  font-family: 'Titillium-SemiBold';
  cursor: pointer;
  margin: auto;
  display: flex;
}

.images {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
}

.image {
  border-radius: 20px;
  height: 180px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 767px) {
}

@media only screen and (min-width: 991px) {
  .seg1 {
    display: flex;
    align-items: center;
    margin: 20px auto;
    align-items: normal;
  }
  .svg {
    margin-right: 25px;
  }
  .logo {
    height: 145px;
    width: 145px;
    margin-right: 25px;
  }
  .title,
  .subtitle {
    text-align: left;
  }
  .image3 {
    object-fit: cover;
  }
  .btn {
    margin: 0;
  }

  .images {
    flex-direction: row;
    gap: 20px;
  }
}
