.settings_s1 {
  font-family: 'Titillium-Regular';
  background-color: #1e1e1e;
  color: white;
}

.edit_body {
  background-color: white;
  margin: auto;
  min-height: 450px;
  padding: 30px;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.2);
  max-width: 1280px;
}

.setting_title_body {
  width: 90%;
}

.profile {
  color: #434343;
  font-size: 45px;
  line-height: 55px;
  font-family: 'Titillium-SemiBold';
}

.user_body {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.user {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  align-self: flex-start;
  font-family: 'Titillium-SemiBold';
}

.photo {
  height: 60px;
  border-radius: 50%;
}

.userName {
  font-size: 20px;
  color: #434343;
}

.strava {
  width: 100%;
  margin-top: 10px;
}

.strava_connected {
  color: #1a1a1a;
  font-family: 'Titillium-SemiBold';
  display: flex;
  align-items: center;
  gap: 10px;
}

.strava_disconnect {
  display: flex;
  color: #1a1a1a;
  text-decoration: underline;
  font-size: 14px;
  font-family: 'Titillium-Regular';
  margin-top: 5px;
  margin-bottom: 30px;
}
.strava_disconnect:hover {
  cursor: pointer;
}

.strava_disconnect:active {
  transform: scale(0.95);
}

.strava_btn_styles {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: white;
  gap: 20px;
}
.strava_btn {
  display: flex;
  background-color: #1a1a1a;
  border: none;
  border-radius: 14px;
  padding: 10px;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.strava_btn:hover {
  cursor: pointer;
}

.strava_btn:active {
  transform: scale(0.95);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
}
.form {
  display: flex;
  flex-direction: column;
}

.form_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin: auto; */
  /* margin-top: 20px; */
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* gap: 20px; */
}

.label {
  color: #1a1a1a;
  margin-bottom: -15px;
  margin-left: 5px;
  font-size: 18px;
  font-family: 'Titillium-SemiBold';
}

.form_block_one {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: auto;
}
.form_block_two {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: auto;
}

.form_btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 20px;
}

.form_textarea {
  /* min-width: 322px; */
  height: 150px;
  border: 1px solid #1a1a1a;
  border-radius: 3px;
  resize: none;
  padding: 10px;
  font-family: 'Titillium-Regular';
  font-size: 16px;
}

input {
  border-radius: 5px;
  border: 1px solid #1a1a1a;
  padding: 10px;
  font-family: 'Titillium-Regular';
  font-size: 16px;
}

.dropdown {
  border-radius: 5px;
  border: 1px solid #1a1a1a;
  padding: 10px;
  font-family: 'Titillium-Regular';
  font-size: 16px;
}

.no_movements {
  height: 50px;
  /* width: 100%; */
  /* color: red; */
  background-color: #231e20;
}

.card_btn {
  background-color: #f1ec08;
  border: none;
  border-radius: 10px;
  width: 60%;
  padding: 8px;
  font-family: 'Titillium-SemiBold';
  font-size: 15px;
  color: #1a1a1a;
}

.your_movements {
  display: flex;
  padding-top: 60px;
  color: #434343;
  font-size: 45px;
  line-height: 55px;
  font-family: 'Titillium-SemiBold';
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  min-height: 100vh;
}

.loading_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loading {
  display: flex;
  height: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

@media only screen and (min-width: 767px) {
  .strava {
    width: 50%;
    display: flex;
    align-self: flex-start;
    max-width: 350px;
  }
  .user_body {
    margin-bottom: 40px;
  }
  .edit_body {
    min-height: 75vh;
  }

  .form_body {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .form_block_one {
    width: 60%;
  }
  .form_block_two {
    width: 70%;
  }

  .form_btn {
    max-width: 280px;
  }
}

@media only screen and (min-width: 991px) {
  .settings_s1 {
    background-color: #ccc;
    padding: 80px;
    margin: auto;
  }
  .edit_body {
    padding: 80px;
    background-color: white;
    border-radius: 15px;
    max-width: 1100px;
    margin: auto;
  }
  .form_body {
    justify-content: space-between;
  }
  .form_block_one {
    max-width: 350px;
    margin: 0;
  }
  .form_block_two {
    max-width: 350px;
  }
}
