/* TitilliumWEb */
@font-face {
  font-family: 'Titillium-Regular';
  src: local('Titillium-Regular'),
    url('../assets/fonts/titillium/TitilliumWeb-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Titillium-ExtraLight';
  src: local('Titillium-Light'),
    url('../assets/fonts/titillium/TitilliumWeb-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Titillium-Light';
  src: local('Titillium-Light'),
    url('../assets/fonts/titillium/TitilliumWeb-Light.woff') format('woff');
}
@font-face {
  font-family: 'Titillium-SemiBold';
  src: local('Titillium-SemiBold'),
    url('../assets/fonts/titillium/TitilliumWeb-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Titillium-Black';
  src: local('Titillium-Black'),
    url('../assets/fonts/titillium/TitilliumWeb-Black.woff') format('woff');
}
@font-face {
  font-family: 'Titillium-Bold';
  src: local('Titillium-Bold'),
    url('../assets/fonts/titillium/TitilliumWeb-Bold.woff') format('woff');
}

/* Oswald */
@font-face {
  font-family: 'Oswald-Regular';
  src: local('Oswald-Regular'),
    url('../assets/fonts/oswald/Oswald-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Oswald-Medium';
  src: local('Oswald-Medium'),
    url('../assets/fonts/oswald/Oswald-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Oswald-SemiBold';
  src: local('Oswald-SemiBold'),
    url('../assets/fonts/oswald/Oswald-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Oswald-Bold';
  src: local('Oswald-Bold'),
    url('../assets/fonts/oswald/Oswald-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Oswald-Light';
  src: local('Oswald-Light'),
    url('../assets/fonts/oswald/Oswald-Light.woff') format('woff');
}

@font-face {
  font-family: 'Oswald-ExtraLight';
  src: local('Oswald-ExtraLight'),
    url('../assets/fonts/oswald/Oswald-ExtraLight.woff') format('woff');
}
