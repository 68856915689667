.body {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
  font-family: 'Titillium-Regular';
  min-height: 80vh;
}

.z {
  display: flex;
  width: 80%;
  margin: 30px auto 0 auto;
}

.text_body {
  color: #434343;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: 56px;
  font-weight: bold;
  line-height: 61.2px;
}

.sub_title {
  font-family: 'Titillium-Light';
}

.btn_group {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.btn_back {
  font-family: 'Titillium-Bold';
  padding: 12px;
  border-radius: 10px;
  border: none;
  background-color: #f1ec08;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 35px;
  padding-right: 35px;
}
.btn_back:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
}

.link {
  margin: auto;
}

.btn_home {
  background-color: #fff;
  border: none;
  font-family: 'Titillium-SemiBold';
  margin-bottom: 30px;
  margin: auto;
}
.btn_home:active {
  transform: scale(0.98);
}

@media only screen and (min-width: 767px) {
  .z {
    margin-top: 100px;
  }

  .btn_group {
    flex-direction: row-reverse;
    align-items: center;
    width: 60%;
    margin: 35px auto;
  }

  .btn_back {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media only screen and (min-width: 991px) {
  .btn_group {
    width: 30%;
    max-width: 400px;
    margin: 50px auto;
  }

  .btn_back,
  .btn_home {
    cursor: pointer;
  }
}
