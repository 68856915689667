.paymentOptions {
  display: flex;
  flex-direction: column;
}

.paymentOption {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px;
  position: relative;
}

.selectedOption {
  border-bottom: none;
}
.hiddenRadio {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.customRadio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-right: 10px;
  position: relative;
}
.hiddenRadio:checked + .customRadio {
  border-color: #f1ec08;
}

.hiddenRadio:checked + .customRadio::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15px;
  width: 15px;
  background-color: #f1ec08;
  border-radius: 50%;
}

@media only screen and (min-width: 767px) {
  .paymentOptions {
    max-width: 500px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin: auto;
  }
}
