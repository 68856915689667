.footer_top {
  /* height: 50px; */
  /* background-color: #000; */
}
.container {
  background-color: #1a1a1a;
  padding-bottom: 30px;
}

.footer_body {
  background-color: #1a1a1a;
  margin-bottom: 30px;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f1ec08;
  color: white;
  padding-top: 50px;
  text-align: center;
  gap: 34px;
  align-items: center;
}
.logo {
  height: 40px;
  width: 40px;
}
.follow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 767px) {
  .copyright {
    width: 75%;
  }
}
@media only screen and (min-width: 991px) {
  .container {
    background-color: #1a1a1a;
    padding-bottom: 20px;
  }
  .footer_body {
    display: grid;
    grid-template: 1fr / 1fr 200px 1fr;
    justify-content: space-around;
    justify-items: center;
  }
  .copyright {
    order: 1;
    /* width: 100%; */
  }
  .follow {
    order: 2;
  }
  .privacy {
    order: 3;
  }
}
