.movement_cards_body {
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  color: #2d2a32;
  padding-bottom: 50px;
  font-family: 'Titillium-Regular';
}
.card_body {
  /* background-color: #231e20; */
  /* background-color: #fff; */
  height: 100%;
  padding: 25px;
  margin-bottom: 20px;
  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* grid-template-rows: repeat(1, 1fr); */
  gap: 30px;
  list-style: none;
  align-items: center;
  justify-items: center;
  width: 70%;
  margin: auto;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  list-style-type: none;
  -webkit-overflow-scrolling: touch;
  /* scroll-behavior: smooth; */
}

.card_spacing {
  width: 88%;
  margin: auto;
}
.card {
  background-color: white;
  height: 350px;
  flex: 0 0 auto;
  width: 280px; /* Adjust the width as needed */
  margin-right: 16px;
  scroll-snap-align: start;
  border-radius: 0 30px;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.2);
}

.background {
  /* width: 150px; */
  width: 100%;
  height: 33%;
  border-radius: 0 30px 0 0;
  /* background-size: cover; */
  background-position: center;
  background-size: contain;
}
.avatar {
  margin-top: -100px;
  margin-left: 15px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;
}

.movement_name {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0;
  margin: auto;
  width: 80%;
}
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30%;
  justify-content: center;
  height: 30%;
  padding: 0;
  margin: 0;
}

.card_details {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.card_detail_2 {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.card_title_one {
  font-size: 1rem;
}

.card_title_two {
  font-size: 1rem;
  font-weight: bold;
}

.card_btn_body {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: end;
  height: 10%;
  margin: auto;
}
.card_btn a {
  color: black;
  font-weight: bold;
}
.card_link {
  display: flex;
  margin: auto;
}
.card_btn {
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  background-color: #f1ec08;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Titillium-SemiBold';
}

.card_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
}

.card a {
  text-decoration: none;
}

.no_movements {
  color: white;
  text-align: center;
  margin: auto;
  font-size: 28px;
}

@media only screen and (min-width: 767px) {
  .card_body {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(1, 400px);
    overflow-x: clip;
    scroll-snap-type: none;
    width: auto;
    /* justify-content: center; */
    align-content: center;
  }
  .no_movements {
    grid-area: 1 / 2;
  }
}

@media only screen and (min-width: 1024px) {
  .card_body {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(1, 400px);
    /* justify-content: center; */
    align-content: center;
  }

  .card:hover {
    transform: scale(1.1);
    -webkit-transition: transform 0.6s ease-in-out;
  }
}

@media only screen and (min-width: 1024px) {
  .card_body {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(1, 400px);
    justify-content: center;
    align-content: center;
    width: auto;
    max-width: 1350px;
  }
}
