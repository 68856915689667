.details_container {
  font-family: 'Titillium-Regular', serif;
  background-image: url('../assets/Images/mfcNewBg.png');
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
}
.container {
  display: flex;
  flex-direction: column;
  color: white;
  min-height: 100vh;
}

.details_section_one {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  order: 1;
}

.details_name {
  font-weight: bolder;
}
.details_desc {
  font-size: 18px;
}
.details_btn {
  background-color: #f1ec08;
  width: 50%;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.details_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.details_section_two {
  display: flex;
  flex-direction: column;
  color: black;
  border: 1px solid #f1ec08;
  width: 80%;
  max-width: 400px;
  min-height: 585px;
  margin: 25px auto;
  gap: 20px;
  background-color: #1a1a1a;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  order: 2;
}

.details_card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}
.card_block_one {
  display: flex;

  height: 10%;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  gap: 30px;
  padding: 5px;
}

.avatar {
  border-radius: 50%;
  height: 40px;
}
/* 
.inner_card_one {
  display: flex;
  flex-direction: column;
} */
.card_title {
  font-weight: bold;
}

.card_block_two {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.card_btn {
  width: 80%;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #f1ec08;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Titillium-SemiBold';
}

.card_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.1);
}

.card_block_three {
  display: flex;
  flex-direction: column;
  height: 50%;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}
.block {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: auto;
}
.goal {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: left;
}
.goal_title {
  color: #bdbdbd;
}
.goal_subtitle {
  font-size: 1.3rem;
  font-weight: bold;
}
.vl {
  border-left: 1px solid black;
  height: 40px;
}

.progress {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.progress_bar {
  width: 200px;
  border-radius: 0;
}

.copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: #bdbdbd;
}
.copy_icon {
  margin-top: 10px;
  /* background-color: white; */
  color: black;
  /* border-radius: 50px; */
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.copy_icon:hover {
  transform: scale(1.1);
}

.copy_icon:active {
  transform: scale(0.8);
}

.donations {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  margin: auto;
  order: 3;
}

.donation_title_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.donation_title {
  font-size: 25px;
}

.donation_body {
  padding: 20px;
  border: 1px solid #f1ec08;
  border-radius: 10px;
  height: 40px;
  max-width: 600px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  background-color: #1a1a1a;
  font-family: 'Titillium-Light';
}
.donation_text {
  margin-left: 20px;
}

.loading {
  display: flex;
  height: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

@media only screen and (max-width: 767px) {
  .details_container {
    /* height: calc(100vh - 60px); */
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    flex-direction: column;
    width: 80%;
    max-width: 1050px;
    margin: auto;
    gap: 20px;
    padding-bottom: 50px;
  }

  .details_section_one {
    width: 60%;
    padding-top: 20px;
    margin: 0 auto;
    gap: 30px;
    max-width: 600px;
  }
  .details_section_two {
    width: 60%;
    max-height: 550px;
    max-width: 600px;
    padding: 20px;
  }

  .donations {
    width: 60%;
    margin: 0 auto;
  }
}

@media only screen and (orientation: landscape) and (max-width: 950px) {
  .details_container {
    /* min-height: 300vh; */
    display: flex;
    flex-flow: column;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 991px) {
  .container {
    flex-direction: row;
  }

  .details_section_one {
    max-width: 400px;
  }

  .details_btn {
    width: 40%;
    height: 40px;
  }

  .details_section_two {
    padding: 0;
    max-width: 400px;
  }

  .donations {
    width: 100%;
  }

  .donation_title {
    font-size: 32px;
  }

  .donation_title_body {
    flex-direction: row;
  }

  .donation_body {
    margin: 15px 0;
  }
}
