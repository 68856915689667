.container {
  display: flex;
  flex-direction: column;
  font-family: 'Titillium-Regular';
  width: 90%;
  margin: auto;
}

.title {
  font-size: 30px;
}

.subtitle1 {
  font-size: 20px;
}

.donations_container {
  width: 80%;
}

.user {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  align-self: flex-start;
  font-family: 'Titillium-SemiBold';
  margin: auto;
}

.photo {
  height: 60px;
  border-radius: 50%;
}

.userName {
  font-size: 16px;
  color: #434343;
}

.charityName {
  display: flex;
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.donationPills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0px;
}

.donationPill {
  font-family: 'Titillium-SemiBold';
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.donationPill:hover {
  background-color: #e0e0e0;
}

.tip_text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px 20px 0px;
}

.tip_title {
  font-family: 'Titillium-SemiBold';
  font-size: 20px;
}
.tip_subtitle {
  font-family: 'Titillium-Light';
  font-size: 16px;
}
.toggle_options {
  display: flex;
  gap: 50px;
  align-items: center;
  margin-top: 20px;
}

.toggle_input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.radio_label {
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  display: flex;
  align-items: center;
}

.radio_button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #ddd;
  background-color: transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.radio_label:hover .radio_button {
  border-color: #f0f0f0;
}

.toggle_input:checked + .radio_label .radio_button {
  border-color: #f1ec08;
}

.toggle_input:checked + .radio_label .radio_button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  background-color: #f1ec08;
  border-radius: 50%;
}

.btn {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.btn {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  text-decoration: none;
}

.continue_btn {
  font-family: 'Titillium-Light';
  padding: 0.8rem;
  border-radius: 1rem;
  border: none;
  background-color: #f1ec08;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 80px;
  padding-right: 80px;
  margin-bottom: 30px;
  cursor: pointer;
}

.creditCardForm {
  background-color: #fff;
  padding: 20px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.formInput {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}
.donationForm {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.back_btn_container {
  width: 80%;
  margin: auto;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}
.back_btn {
  background-color: #f1ec08;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (min-width: 767px) {
  .container {
    width: 70%;
  }

  .donationPills {
    display: flex;
    gap: 10px;
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 991px) {
  body {
    background-color: #ccc;
  }
  .container {
    width: 50%;
    background-color: white;
    padding: 50px;
    margin: 100px auto;
    border-radius: 10px;
    max-width: 800px;
  }
}
