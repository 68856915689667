.container {
  display: flex;
  flex-direction: column;
  font-family: 'Titillium-Regular';
  align-items: center;
  justify-content: center;
  height: 75vh;
  color: #434343;
  gap: 23px;
  /* color: #1e1e1e; */
}
.logo {
  height: 100px;
}
.text1 {
  font-size: 40px;
  font-weight: bold;
}
.text2,
.text3 {
  font-size: 20px;
  font-family: 'Titillium-SemiBold';
}

.btn {
  font-family: 'Titillium-Light';
  padding: 0.8rem;
  border-radius: 1rem;
  border: none;
  background-color: #f1ec08;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 30px;
  cursor: pointer;
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #ccc; */
  min-height: 100vh;
}

.loading_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (min-width: 991px) {
  .btn {
    padding-left: 100px;
    padding-right: 100px;
  }
}
