@import './fonts.module.css';

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.movement_body {
  background-color: #1a1a1a;
  height: 100vh;
  color: white;
  /* margin-bottom: 10px; */
}

.title {
  /* text-align: center; */
  /* margin-top: 2rem; */
  /* margin-bottom: 2rem; */
  font-size: 2rem;
  /* color: #eeefa8; */
  color: #fff;
  background-color: #000;
  border-bottom: 1px solid #f1ec08;
  height: 120px;
  display: flex;
  /* align-content: center; */
  justify-content: center;
  margin: auto;
  align-items: center;
}

.swipe {
  margin: auto;
  display: flex;
  text-align: left;
  width: 80%;
  padding: 20px;
  color: white;
}

.movements_table {
  width: 80%;
  margin: auto;
}

.strava_btn {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 1rem;
  /* background-color: '#F55104'; */
  border: 0;
  border-radius: 10px;
}

.strava_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.btn_styles {
  display: flex;
  /* flex-direction: column; */
  margin: auto;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  gap: 0.8rem;
}

.pagination_body {
  display: flex;
  flex-direction: row;
  gap: 15px;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px;
  cursor: pointer;
  outline: none;
  max-width: 80%;
}

.active_item {
  display: flex;
  justify-content: center;
  background-color: #f1ec08;
  border-radius: 50%;
  padding: 5px;
  width: 20px;
}
.active_item a {
  color: #1e1e1e;
  /* color: transparent; */
}

.no_movements {
  color: white;
  text-align: center;
  margin: auto;
  font-size: 28px;
}
/* 
    display: flex;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #f1ec08;
    color: transparent;
    width: 20px;
*/
