.hp_main {
  display: flex;
  flex-direction: column;
}
.section_one {
  font-family: 'Titillium-Regular', serif;
  background-image: url('../assets/Images/mfcNewBg.png');
  background-color: #231e20;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  min-height: 600px;
}
.section_two {
  background-color: #eae151;
}

.section_three {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #231e20;
}
/* .create_movement_btn {
  padding: 0.9rem;
  border-radius: 15px;
  border: none;
  background-color: #f1ec08;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
} */
.s1_body_container {
  /* display: grid; */
  width: 90%;
  margin: auto;
  /* grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0.2fr; */
}
.s1_body {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  margin: auto;
}

.s1_title {
  /* color: #eae151; */
  color: white;
  text-align: center;
  font-size: 3.5rem;
}

.s2_title {
  font-size: 2.5rem;
  margin: 25px;
  color: #231e20;
  padding: 10px;
}

.s1_subtitle {
  color: #fafdf6;
  text-align: center;
  /* width: 55%; */
  letter-spacing: 1px;
  margin: auto;
  font-family: 'Titillium-Light';
  font-size: 1.05rem;
  margin-bottom: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.parent {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-bottom: 200px;
}
.img_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
}
.image1 {
  height: 200px;
  position: relative;
  top: 100px;
  left: 100px;
}
.image2 {
  position: relative;
  height: 200px;
  left: -20px;
}

.s2_list {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 1fr;
  width: 80%;
  margin: auto;
}
.s2_list_title {
  font-size: 2.3rem;
  font-weight: bolder;
  margin: 8px;
}
.s2_list_subtitle {
  font-size: 1.2rem;
}

.btn {
  display: flex;
  text-decoration: none;
  margin-top: 2rem;
}
.section_three {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.s3_body {
  display: flex;
  flex-direction: column;
}

.s3_inner_body {
  display: flex;
  flex-direction: column;
  color: #434343;
  margin: 0 auto;
  padding: 15px;
  gap: 10px;
  background-color: #fff;
  width: 75%;
}
.s3_title {
  font-family: 'Titillium-Regular';
  font-size: 52px;
  text-align: left;
  margin: 0;
}
._18k {
  font-size: 53px;
  color: #08a3f1;
}
.s3_subtitle {
  font-size: 18px;
}
.create_movement_btn {
  font-family: 'Titillium-Light';
  padding: 0.8rem;
  border-radius: 1rem;
  border: none;
  background-color: #f1ec08;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 30px;
  cursor: pointer;
}

.create_movement_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.s4_body {
  background-color: #eae151;
  text-align: center;
  padding-bottom: 20px;
}
.s4_marquee {
  width: 70%;
  margin: auto;
}
.marquee {
  width: 80%;
  margin: auto;
}
.marquee_img {
  max-width: 300px;
  max-height: 300px;
  padding: 10px;
}

/* @media screen and (max-width: 868px) {
  .section_one {
    background-size: cover;
  }
  .s1_title {
    font-size: 2.2rem;
  }
  .s1_subtitle {
    color: #fafdf6;
    width: 110%;
    margin-bottom: 20px;
   margin: auto; 
    font-size: 1.3rem;
  } */

@media only screen and (min-width: 767px) and (max-width: 990px) {
  .s1_body {
    width: 75%;
  }
  .s1_title,
  .s1_subtitle {
    text-align: left;
  }
  .btn {
    justify-content: flex-start;
  }
  .parent {
    width: 75%;
  }
  .image1,
  .image2 {
    height: 250px;
  }
  .image3 {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 50px;
    width: 75%;
    height: 380px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 991px) {
  .s1_body_container {
    width: 80%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
  }
  .s1_body {
    display: flex;
    align-items: flex-start;
    width: 50%;
  }

  .s1_title,
  .s1_subtitle {
    text-align: left;
  }
  .parent {
    margin-bottom: 0px;
  }
  .img_body {
    align-items: flex-start;
  }
  .image1,
  .image2 {
    height: 270px;
    width: 320px;
  }
  .s3_inner_body {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    max-width: 1300px;
  }
  .s3_paragraph {
    width: 70%;
  }
  .s3_title {
    font-size: 45px;
    margin-bottom: 0;
  }
  .s3_subtitle {
    font-size: 15px;
  }
  ._18k {
    font-size: 50px;
  }
  .image3 {
    order: -1;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 50px;
    width: 30%;
    height: 300px;
    margin-bottom: 50px;
  }
}
