.stripeForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cardDetails {
  display: flex;
  flex-direction: column;
}

.donateBtn {
  font-family: 'Titillium-Light';
  padding: 0.8rem;
  border-radius: 1rem;
  border: none;
  background-color: #f1ec08;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 80px;
  padding-right: 80px;
  margin-bottom: 30px;
  cursor: pointer;
}

.cardNumber {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Titillium-Light';
}
